const SearchIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fillRule="evenodd"
      stroke="#000000"
      d="M16.902 16.347a.332.332 0 0 1 .098.245c0 .098-.033.18-.098.245-.087.065-.163.098-.229.098a.332.332 0 0 1-.244-.098l-5.47-5.42c-1.077.957-2.422 1.463-4.032 1.518-1.698-.055-3.092-.642-4.18-1.764C1.626 10.072 1.044 8.657 1 6.927c.054-1.677.642-3.075 1.763-4.196C3.884 1.62 5.273 1.044 6.927 1c1.708.044 3.134.62 4.277 1.73 1.1 1.122 1.676 2.52 1.73 4.197-.054 1.621-.554 2.955-1.501 4l5.469 5.42zM3.22 10.698c.99 1.012 2.226 1.535 3.707 1.567 1.512-.032 2.775-.555 3.787-1.567 1.002-1.001 1.519-2.259 1.551-3.771-.032-1.492-.55-2.727-1.55-3.707C9.69 2.22 8.428 1.702 6.926 1.67c-1.47.032-2.705.549-3.707 1.55-1.001.98-1.518 2.215-1.55 3.707.032 1.512.549 2.77 1.55 3.771z"
    />
  </svg>
);

export default SearchIcon;
