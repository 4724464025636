import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz/client';
import { CloseButton } from '@spotahome/ui-library';

import mapPointShape from '../../shapes/mapPoint';
import SearchIcon from '../icons/SearchIcon';
import debounce from '../../utils/debounce';
import { getPlaceDetails } from '../../api/places-client';
import { useGoogleMapsScript } from '../../utils/google-maps-script';

import { normalizeLocalityToCityId } from './utils';
import PlacesAutocompleteSuggestion from './PlacesAutocompleteSuggestion';

import './PlacesAutocomplete.scss';

const BASE_CLASS = 'places-autocomplete';

const SessionToken = () => {
  let token = new window.google.maps.places.AutocompleteSessionToken();

  const renew = () =>
    (token = new window.google.maps.places.AutocompleteSessionToken());

  const getToken = () => token;

  const getTokenAsString = () => token.xn;

  return {
    renew,
    getToken,
    getTokenAsString
  };
};

const useAutocompleteService = ({
  countryCode,
  boundCoords,
  pointOfInterest,
  onPlaceSelected
}) => {
  const autocompleteSessionToken = useRef(null);
  const autocompleteService = useRef(null);
  const placesRef = useRef(null);
  const [inputValue, setInputValue] = useState(pointOfInterest?.name);

  const [suggestionList, setSuggestionList] = useState([]);

  const closeSuggestions = e => {
    if (placesRef.current && !placesRef.current.contains(e.target)) {
      setSuggestionList([]);
    }
  };

  const handleLoadGoogleMapsScript = () => {
    autocompleteSessionToken.current = SessionToken();
    autocompleteService.current =
      new window.google.maps.places.AutocompleteService();
  };

  useEffect(() => {
    document.addEventListener('click', closeSuggestions);

    return () => document.removeEventListener('click', closeSuggestions);
  }, []);

  useGoogleMapsScript(handleLoadGoogleMapsScript);

  useEffect(() => {
    setInputValue(pointOfInterest ? pointOfInterest.name : '');
  }, [pointOfInterest]);

  const requestSuggestions = useRef(
    debounce(value => {
      if (value.length > 2) {
        autocompleteService.current.getPlacePredictions(
          {
            input: value,
            radius: '50000',
            location: new window.google.maps.LatLng({
              lat: parseFloat(boundCoords.lat),
              lng: parseFloat(boundCoords.lng)
            }),
            sessionToken: autocompleteSessionToken.current.getToken(),
            componentRestrictions: {
              country: countryCode
            }
          },
          results => {
            // TODO: review no results use case
            setSuggestionList(results || []);
          }
        );
      }
    }, 300)
  );

  const handleInput = e => {
    setInputValue(e.target.value);
    requestSuggestions.current(e.target.value);
  };

  const setSelectedValue = placeSelected => {
    setInputValue(placeSelected.description);
    setSuggestionList([]);

    onPlaceSelected(placeSelected);
  };

  const handleSelect = (placeId, description) => async () => {
    if (placeId) {
      const response = await getPlaceDetails(
        placeId,
        'geometry,address_component',
        autocompleteSessionToken.current.getTokenAsString()
      );
      const address = response.data.result.address_components;
      // eslint-disable-next-line camelcase
      const { long_name } =
        address.find(({ types }) => types.includes('locality')) || {};

      const cityId = normalizeLocalityToCityId(long_name);

      setSelectedValue({
        placeId,
        cityId,
        ...response.data.result.geometry.location,
        name: description
      });

      autocompleteSessionToken.current.renew();
    }
  };

  return {
    handleInput,
    suggestionList,
    inputValue,
    placesRef,
    handleSelect
  };
};

const PlacesAutocomplete = ({
  pointOfInterest,
  boundCoords,
  countryCode,
  onPlaceSelected,
  flat,
  renderExtraContent
}) => {
  const { handleInput, inputValue, suggestionList, placesRef, handleSelect } =
    useAutocompleteService({
      boundCoords,
      countryCode,
      pointOfInterest,
      onPlaceSelected
    });

  return (
    <div className={BASE_CLASS} data-test="map-search-bar" ref={placesRef}>
      <div className={`${BASE_CLASS}__box`}>
        <SearchIcon className={`${BASE_CLASS}__icon`} />
        <input
          className={`${BASE_CLASS}__input ${
            flat ? `${BASE_CLASS}__input--flat` : ''
          }`}
          value={inputValue}
          onChange={handleInput}
          placeholder={trans('rentable_unit_map.autocomplete.placeholder')}
        />
        {pointOfInterest && (
          <CloseButton
            onClick={() => onPlaceSelected(null)}
            className={`${BASE_CLASS}__clear`}
            role="button"
            tabIndex={0}
            data-test="map-search-clear"
          />
        )}
      </div>
      {suggestionList.length ? (
        <ul
          className={`${BASE_CLASS}__suggestions`}
          data-test="poi-search-suggestions"
        >
          {suggestionList.map(suggestion => (
            <PlacesAutocompleteSuggestion
              key={suggestion.place_id}
              structuredFormatting={suggestion.structured_formatting}
              onClick={handleSelect(
                suggestion.place_id,
                suggestion.description
              )}
            />
          ))}
        </ul>
      ) : null}
      {renderExtraContent()}
    </div>
  );
};

PlacesAutocomplete.propTypes = {
  boundCoords: PropTypes.shape(mapPointShape).isRequired,
  pointOfInterest: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string,
    placeId: PropTypes.string
  }),
  countryCode: PropTypes.string,
  flat: PropTypes.bool,
  onPlaceSelected: PropTypes.func,
  renderExtraContent: PropTypes.func
};

PlacesAutocomplete.defaultProps = {
  pointOfInterest: undefined,
  countryCode: undefined,
  flat: false,
  onPlaceSelected: () => {},
  renderExtraContent: () => {}
};

export default PlacesAutocomplete;
