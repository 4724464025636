import axios from 'axios';

const DEFAULT_DOMAIN = '';
const API_PREFIX = 'api/fe/marketplace';

export function getPlaceDetails(placeId, fields, sessionToken, options = {}) {
  const { cookie, domain = DEFAULT_DOMAIN } = options;
  const params = { placeId, fields, sessionToken };
  const path = `${domain}/${API_PREFIX}/places/details`;
  const headers = {};

  if (cookie) {
    headers.cookie = cookie;
  }

  return axios.get(path, { headers, params });
}

export default {
  getPlaceDetails
};
