import { useEffect } from 'react';

import { useSoyuzClientConfig, useSoyuzLocales } from '@spotahome/soyuz/client';
import { Mediator } from '@spotahome/soyuz-mediator';

const MAP_LOADED = 'google-map-loaded';
const MAP_SCRIPT_ID = 'google-map-script';

export const useGoogleMapsScript = callback => {
  const { current: currentLocale } = useSoyuzLocales();
  const { googleMapsApiKey } = useSoyuzClientConfig();

  const handleMapLoaded = () => {
    Mediator.publish(MAP_LOADED);
  };

  useEffect(() => {
    const isGoogleMapsLoaded =
      window.google && typeof window.google.maps !== 'undefined';
    const isScriptAreadyInjected = document.querySelector(`#${MAP_SCRIPT_ID}`);

    if (isGoogleMapsLoaded) {
      callback();
    } else if (isScriptAreadyInjected) {
      Mediator.subscribe(MAP_LOADED, callback);
    } else {
      Mediator.subscribe(MAP_LOADED, callback);

      const gmapScript = document.createElement('script');
      gmapScript.id = MAP_SCRIPT_ID;
      gmapScript.src = `//maps.googleapis.com/maps/api/js?v=quarterly&key=${googleMapsApiKey}&language=${currentLocale}&libraries=places`;
      gmapScript.onload = handleMapLoaded;
      document.body.appendChild(gmapScript);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default {
  useGoogleMapsScript
};
