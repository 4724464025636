/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import PropTypes from 'prop-types';

import './PlacesAutocompleteSuggestion.scss';

const getHighlightedResult = (text, matches) => {
  const { offset, length } =
    matches && matches.length ? matches[0] : { offset: 0, length: text.length };
  const explodedSuggestion = [...text];
  const match = text.slice(offset, offset + length);
  explodedSuggestion.splice(offset, length, `<span>${match}</span>`);

  return explodedSuggestion.join('');
};

const PlacesAutocompleteSuggestion = ({ structuredFormatting, onClick }) => (
  <li
    onClick={onClick}
    className="places-autocomplete-suggestion"
    role="button"
    tabIndex={0}
  >
    <span
      dangerouslySetInnerHTML={{
        __html: getHighlightedResult(
          structuredFormatting.main_text,
          structuredFormatting.main_text_matched_substrings
        )
      }}
    />
    , {structuredFormatting.secondary_text}
  </li>
);

PlacesAutocompleteSuggestion.propTypes = {
  structuredFormatting: PropTypes.shape({
    main_text: PropTypes.string,
    secondary_text: PropTypes.string,
    main_text_matched_substrings: PropTypes.arrayOf(
      PropTypes.shape({
        length: PropTypes.number,
        offset: PropTypes.number
      })
    )
  }).isRequired,
  onClick: PropTypes.func.isRequired
};

export default PlacesAutocompleteSuggestion;
